import {
  Box,
  chakra,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import JobPreview from '../../components/join-the-team/JobPreview';
import SEO from '../../components/seo';

function JoinTheTeamPage({ data }) {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="100%"
        mt={6}
        mb={16}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            flexDir="column"
            justifyContent="space-between"
            w={{ base: '100%', lg: '50%' }}
            mr={20}
            order={{ base: 2, lg: 1 }}
            flexGrow={1}
          >
            <Flex flexDir="column" flexGrow={3}>
              <Heading as="h1" mt={10}>
                {data.header.heading}
              </Heading>
              <Text mt={3}>{data.header.introParagraph.internal.content}</Text>
            </Flex>
          </Flex>

          <Box
            w={{ base: '100%', lg: '50%' }}
            order={{ base: 1, lg: 2 }}
            overflow="hidden"
            boxShadow="lg"
            borderRadius="lg"
            mt={10}
            maxH={{ base: '15rem', md: '20rem', lg: '100%' }}
          >
            <Img
              image={getImage(data.header.headerImage.gatsbyImage)}
              alt={data.header.headerImage.title}
            />
          </Box>
        </Flex>
        <Divider w="100%" my={16} />
        <Heading size="lg">Open positions</Heading>
        <Stack mt={8} w="100%">
          {data.jobs.edges?.length ? (
            data.jobs.edges.map((edge, index) => (
              <JobPreview
                key={`${edge.node.title}-${index}`}
                title={edge.node.jobTitle}
                description={edge.node.positionDescription}
                dateListed={edge.node.dateListed}
              />
            ))
          ) : (
            <Text>No positions currently open.</Text>
          )}
        </Stack>
      </Flex>
    </>
  );
}

export default JoinTheTeamPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "join-the-team" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: contentfulEntry(contentful_id: { eq: "20krHkYDOJuffGuYGVLKls" }) {
      ... on ContentfulPageHeader {
        id
        heading
        introParagraph {
          internal {
            content
          }
        }
        headerImage {
          title
          gatsbyImage(width: 900)
        }
      }
    }
    jobs: allContentfulJobListing {
      edges {
        node {
          jobTitle
          positionDescription {
            raw
          }
          dateListed(formatString: "Do MMMM, yyyy")
        }
      }
    }
  }
`;
