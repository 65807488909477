import {
  chakra,
  Flex,
  Heading,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { renderMarkup } from 'react-render-markup';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <chakra.span fontWeight="semibold">{text}</chakra.span>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text my={2}>{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <Heading size="xl" mt={6} mb={2}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading size="lg" mt={6} mb={2}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading size="md" mt={6} mb={2}>
        {children}
      </Heading>
    ),
  },
};

interface IJobPreviewProps {
  title: string;
  description: any;
  dateListed: string;
}

function JobPreview({ title, description, dateListed }: IJobPreviewProps) {
  return (
    <Flex
      w="100%"
      p={5}
      borderRadius="2xl"
      border="1px solid"
      borderColor="gray.200"
      flexDir="column"
    >
      <Heading size="lg">{title}</Heading>
      <Text fontSize="sm" color="gray.700" mt={2}>
        Byron Bay, NSW
      </Text>
      <Text fontStyle="italic" fontSize="xs" color="gray.500" mt={2}>
        Posted on the {dateListed}
      </Text>

      {description && renderRichText(description, options)}
    </Flex>
  );
}

export default JobPreview;
